<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('final_grades')" :is-filter="false" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('final_grades')" :is-filter="false" />
            </template>
            <div class="row">
                <div class="col-12 col-lg-3 col-xxl-3 mb-3 mb-md-3 mb-lg-0">
                    <div class="section-filter">
                        <b-form-group :label="$t('section')">
                            <b-form-input v-model="filterSection"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="border p-4 rounded overflow-y-scroll overflow-x-hidden w-100 d-lg-block"
                        style="height: calc(100vh - 230px); min-height: 300px; max-height: `${innerHeight}px`; overflow-y: scroll">
                        <div>
                            <div v-if="isClass === true">
                                <div class="course" v-for="(item, key) in filterSetClass"
                                    :class="selectedCourse === item.section_id ? 'selected-item' : ''"
                                    :id="'course-' + item.section_id" :ref="'course-' + item.section_id" :key="key" @click="() => {
                                        selectedCourse = item.section_id
                                        getExamDefinitions(item.section_id, item.course_name)
                                    }">
                                    <div class="name">
                                        {{ item.course_name }}
                                    </div>
                                    <div class="other">
                                        <b-badge variant="dark">
                                            {{ $t('section_1') }} :{{ item.section }}
                                        </b-badge>
                                        <b-badge variant="dark" class="ml-2">
                                            {{ item.course_code }}
                                        </b-badge>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="spinner-border text-light mx-auto d-flex"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-9 col-xxl-9 mt-1 mt-lg-0" v-if="tableStatus == 'examDefinition'">
                    <template>
                        <ExamDefinitionTable :courseName="course_name" @setExamType="setExamType"
                            @tableStatusUpdate="tableStatusUpdate" :section_id="section_id" />
                    </template>
                </div>
                <div class="col-12 col-lg-9 col-xxl-9 mt-1 mt-lg-0" v-if="tableStatus == 'exam'">
                    <template>
                        <ExamsTable :examId="examId" :examTypeText="examTypeText" :examType="examType" :courseName="course_name" :section_id="section_id"
                            :numberOfExams="numberOfExams" :effectRatio="effectRatio" @tableStatusUpdate="tableStatusUpdate" />
                    </template>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout';
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import AttendanceEntryService from "@/services/AttendanceEntryService";
import ExamDefinitionTable from "@/modules/examDefinitions/pages/ExamDefinitionTable/ExamDefinitionTable.vue";
import ExamService from "@/services/ExamService";

export default {
    name: "ExamDefinitions",
    components: {
        ExamDefinitionTable,
        AppLayout,
        HeaderMobile,
        ExamsTable: () => import("@/modules/examDefinitions/pages/ExamsTable/ExamsTable.vue")
    },
    metaInfo() {
        return {
            title: this.$t('final_grades')
        };
    },
    data() {
        return {
            filterSection: "",
            examDefinitionId: null,
            course_name: null,
            section_id: null,
            selectedCourse: null,
            setClass: [],
            isClass: false,
            examId: null,
            examTypeText: null,
            examType: null,
            numberOfExams: null,
            effectRatio: null,
            tableStatus: "examDefinition"
        };
    },
    computed: {
        filterSetClass() {

            if (this.filterSection) {
                return this.setClass.filter(item => item.course_name.toLowerCase().includes(this.filterSection.toLowerCase()))
            }
            return this.setClass
        },
        innerHeight() {
            return window.innerHeight
        }
    },
    created() {
        this.setModule()
    },
    methods: {
        setExamType({ examTypeText, examId, number_of_exams, effect_ratio, exam_type }) {
            this.examId = examId
            this.examTypeText = examTypeText
            this.examType = exam_type
            this.numberOfExams = number_of_exams
            this.effectRatio = effect_ratio
            this.tableStatus = 'exam'
        },
        tableStatusUpdate(value) {
            this.tableStatus = value
        },
        getExamDefinitions(section_id, course_name) {
            this.tableStatus = 'examDefinition'
            this.section_id = section_id
            this.course_name = course_name
            this.setModule()
        },
        setModule() {
            AttendanceEntryService.getAll().then((response) => {
                this.isClass = true
                this.setClass = response.data.data;
            });
        },

    }
}
</script>
<style scoped>
.course {
    border-radius: 5px;
    height: 100%;
    border: 1px solid #e9ecef;
    padding: 10px;
    margin-bottom: 10px;
}

.course .name {
    font-size: 16px;
    margin-bottom: 20px;
}

.selected-item {
    background: #00aeef !important;
    color: #fff !important;
}
</style>

